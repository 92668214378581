body {
  font-family: "Open Sans", sans-serif;
  background-color: white;
  margin: 0;
  padding: 0;
}

.logo-img {
  width: 105px;
  height: 35px;
  margin: 7px;
  float: left;
}

.Header {
  position: relative;
  display: block;
}

.nav-container {
  display: flex;
  justify-content: flex-end;
}

nav {
  position: relative;
  margin: 27px auto 0;
  width: 100;
  height: 50px;
  background-color: #3d8ecc;
  font-size: 0;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
}

nav a {
  font-size: 15px;
  color: white;
  text-decoration: none;
  line-height: 50px;
}

a {
  float: right;
  margin-right: 30px;
}
