.page-container {
  margin: 80px;
}

.about-us-heading {
  color: rgb(62, 62, 149);
  text-align: left;
  font-size: 60px;
}

.about-us-content{
text-align: left;
font-size: 30px;
}
